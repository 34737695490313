import { Axios } from 'utils/Axios';

// user login
export const userLogin = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}account/login/`,
    body
  );
  return data;
};
export const corporateLogin = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}corporate/login/`,
    body
  );
  return data;
};

export const corporatePasswordChanged = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}corporate/change-password/`,
    body
  );
  return data;
};

// create bank account
export const createAccount = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}account/open-account/`,
    body
  );
  return data;
};

// register user
export const registerUser = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}account/signup/`,
    body
  );
  return data;
};

// Request OTP for user registration
export const requestSignUpOtp = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}account/signup-otp/`,
    body
  );
  return data;
};

// Reset OTP
export const resetOtp = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}account/reset-otp/`,
    body
  );
  return data;
};

// Reset transaction pin
export const resetTransactionPin = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}account/reset-pin/`,
    body
  );
  return data;
};

// Change transaction pin
export const changeTransactionPin = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}account/change-pin/`,
    body
  );
  return data;
};

// Set New Limit
export const setNewTransactionLimit = async (body) => {
  const { data } = await Axios.put(
    `${process.env.REACT_APP_API}corporate/limit/`,
    body
  );
  return data;
};

// Change password
export const changePassword = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}account/change-password/`,
    body
  );
  return data;
};

export const changePasswordCorporate = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}corporate/change-password/`,
    body
  );
  return data;
};

// forgot password
export const forgotPassword = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}account/forgot-password/`,
    body
  );
  return data;
};
// forgot password - corporate
// request otp
export const CorporateRequestOtp = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}corporate/auth/request-reset-token/`,
    body
  );
  return data;
};

// validate otp
export const CorporateValidateOtp = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}corporate/auth/validate-reset-token/`,
    body
  );
  return data;
};

// reset corporate password
export const CorporateResetPassword = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}corporate/auth/set-new-password/`,
    body
  );
  return data;
};


// validate BVN 
export const validateBvn = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}account/validate-bvn/${process.env.REACT_APP_BANK_ID}/`,
    body
  );
  return data;
};

// Update user account details
export const updateProfile = async (body) => {
  const { data } = await Axios.put(
    `${process.env.REACT_APP_API}account/profile/`,
    body
  );
  return data;
};
